import React, { Fragment } from "react"
import { Link } from "gatsby"
import { Typography, Button } from "@material-ui/core"
import useStyles from "./style"
const CategoryListTag = props => {
  const classes = useStyles()
  const { categories, linkPage } = props
  return (
    <Fragment>
      <Link to={linkPage} style={{ textDecoration: "none" }}>
        <Button
          className={classes.buttonLink}
          variant="contained"
          color="primary"
          disableElevation
        >
          <Typography variant="body1">All</Typography>
        </Button>
      </Link>
      {categories.map((category, index) => (
        <Link to={category.href} style={{ textDecoration: "none" }}>
          <Button
            key={index}
            className={classes.buttonLink}
            variant="outlined"
            color="primary"
            disableElevation
          >
            <Typography variant="body1" className={classes.categoryTagName}>{category.name}</Typography>
          </Button>
        </Link>
      ))}
    </Fragment>
  )
}

export default CategoryListTag
