import { makeStyles } from '@material-ui/core/styles'

const containerWidth = '80%'
const useStyles = makeStyles( theme => ({
    root:{
        minWidth:containerWidth,
        maxWidth:containerWidth
    },
    categoryItem:{
        display:'flex',
        // justifyContent:'flex-start',
        justifyContent:'center',
    }
}))
export default useStyles