import React from "react"
import PropTypes from 'prop-types'
import { Link } from "gatsby"
import {
  Box,
  Card,
  CardMedia,
  Typography,
} from "@material-ui/core"
import useStyles from "./style"
const CategoryListCard = props => {
  const classes = useStyles()
  const { categoryName, categoryImage, categoryUrl } = props
  // console.log('categoryImage', categoryImage)
  return (
    <Card className={classes.root}>
      <Box className={classes.cardDetail}>
        <Typography variant="h2" className={classes.cardTitle}>
          {categoryName}
        </Typography>

        <Link to={categoryUrl} className={classes.cardLink}>
          <Typography variant="body1">View Products</Typography>
        </Link>
      </Box>
      <CardMedia className={classes.cover} image={categoryImage}/>
    </Card>
  )
}

export default CategoryListCard

CategoryListCard.propTypes = {
    categoryName: PropTypes.string.isRequired,
    categoryUrl: PropTypes.string.isRequired,
}