import { makeStyles } from '@material-ui/core/styles'

const buttonWidth  = "18.563rem" // 297px
const buttonHeight = "3rem" // 48px
const buttonRadius = "4.063rem" // 65px

const useStyles = makeStyles( theme => ({
    root:{
        [theme.breakpoints.down("md")]: {
            
        }
    },
    buttonLink:{
        textTransform:'capitalize',
        color:'#FFF',
        minHeight:buttonHeight,
        maxHeight:buttonHeight,
        minWidth:buttonWidth,
        maxWidth:buttonWidth,
        borderRadius:buttonRadius,
        [theme.breakpoints.down("md")]: {
            minHeight:'3rem',
            maxHeight:'3rem',
            // minWidth:'15rem',
            // maxWidth:'15rem',
            marginTop:'15px'
        }
    }
})) 
export default useStyles