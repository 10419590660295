import React from "react"
import { Box, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'

import SEO from "./seo"

import Wrapper from "./Wrapper"
import Headline from "./Headline"
import Navbar from "./Navbar"
import Heading from "./Heading"
import DownloadCatalogButton from "./DownloadCatalogButton"
import CategoryListTag from "./CategoryListTag"
import CategoryList from "./CategoryList"
import Footer from './Footer'

import SampleImage from "../images/sample-img.png"
// import { useStaticQuery, graphql } from 'gatsby'

const useStyles = makeStyles( theme => ({
  headingWrapper:{
    [theme.breakpoints.down("md")]: {

    }
  },
  headingTextWrapper:{
    [theme.breakpoints.down("md")]: {
      flexDirection:'column',
      alignItems:'flex-start',
    }
  },
  headingText:{
    fontSize:'35px'
  }
}))

function makeSlug(string) {
  return string
    .toLowerCase()
    .replace(/[^\w ]+/g,'')
    .replace(/ +/g,'-')
}

const IndexPage = ({ pageContext }) => {
  const classes = useStyles()
  let listCategory = []
  pageContext.category.map(cat => {
    const slug = makeSlug(cat.namaKategori)
    const pathTo = `/products/${slug}`
    let kateg = cat.namaKategori.toLowerCase()
    kateg = kateg.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase())
    const obj = { name: kateg, images: cat.gambar.url, href: pathTo }
    listCategory.push(obj)
  })
  return (<Wrapper whatsappNumber={pageContext.footer.whatsappNumber} >
    <SEO title="Products" />
    <Headline />
    <Navbar />

    {/* minHeight: 300px */}
    <Heading className={classes.headingWrapper} minHeight="18.75rem">
      {/* Title Heading Area*/}
      <Box
        className="heading-text-wrapper"
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={5}
        className={classes.headingTextWrapper}
      >
        <Box>
          <Typography variant="h1" className={classes.headingText}>Our Products</Typography>
        </Box>
        <Box>
          <DownloadCatalogButton catalog={pageContext.catalog} />
        </Box>
      </Box>

      {/* Category List Tag Area */}
      <Box
        className="category-list-wrapper"
        width="95%"
        display="flex"
        flexWrap="wrap"
        justifyContent="flex-start"
        alignItems="center"
      >
        <CategoryListTag categories={listCategory} linkPage="/products"/>
      </Box>
    </Heading>
    <Box
      className="contact-form-wrapper"
      display="flex"
      justifyContent="center"
      paddingY="5.625rem"
      bgcolor="#FFF"
    >
      <CategoryList categories={listCategory} />
    </Box>
    <Footer data={pageContext.footer}/>
  </Wrapper>
  )
}

export default IndexPage
