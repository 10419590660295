import { makeStyles } from '@material-ui/core/styles'

const padding = "3rem" // 40px
const useStyles = makeStyles( theme => ({
    root:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        // background:theme.palette.primary.light,
        padding: `${padding} 0`,
        
    },
    withImage:{
        backgroundRepeat:'no-repeat !important',
        backgroundSize:'cover !important',
        backgroundPosition:'center'
        // [theme.breakpoints.down("md")]: {
        //     backgroundPosition:'center'
        // }
    }
}))
export default useStyles