import React from "react"
import { Link } from "gatsby"
import { Button, Typography } from "@material-ui/core"
import useStyles from "./style"

const DownloadCatalogButton = (props) => {
  const classes = useStyles()
  // console.log(props.catalog)
  return (
    // <Link to={props.catalog} style={{textDecoration:'none'}}>
    <a href={props.catalog} style={{ textDecoration: "none" }} >
      <Button variant="contained" color="secondary" disableElevation className={classes.buttonLink}>
        <Typography variant="body1">Download Product Catalog</Typography>
      </Button>
    </a>
    // </Link>
  )
}

export default DownloadCatalogButton
