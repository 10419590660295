import React from 'react'
import { Box, Grid } from '@material-ui/core'
import CategoryListCard from '../CategoryListCard'
import useStyles from './style'
const CategoryList = props => {
    const classes = useStyles()
    const { categories } = props
    return (
        <Box className={classes.root}>
            <Grid container spacing={3}>
                {categories.map( (category, index) =>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.categoryItem} key={index}>
                        <CategoryListCard categoryName={category.name} categoryUrl={category.href} categoryImage={category.images}/>
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}

export default CategoryList
