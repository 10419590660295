import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import useStyles from './style'
const Heading = (props) => {
    const classes = useStyles()
    const { children, minHeight, background } = props
    const bgColor = background ? `url(${background})` : "#F3FAF6"
    return (
        <Box width="100%" className={classNames(classes.root, background?classes.withImage : undefined)} style={{minHeight:minHeight, background:bgColor}}> 
            <Box width="80%" display="flex" flexDirection="column">
                {children} 
            </Box>
        </Box>
    )
}

export default Heading

Heading.propTypes = {
    minHeight: PropTypes.string
}
