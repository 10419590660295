import { makeStyles } from '@material-ui/core/styles'

// const cardWidth  = "34.625rem" // 554px
// const cardHeight = "13.188rem" // 211px
const cardWidth  = "100%" // 554px
const cardHeight = "13.188rem" // 211px
const cardPadding = "1.875rem" // 30px
const cardDetailWidth = "15rem" // 240px
const imgWidth = "19.625rem" // 314px
const useStyles = makeStyles( theme => ({
    root:{
        display:'flex',
        justifyContent:'space-between',
        minWidth:cardWidth,
        maxWidth:cardWidth,
  
        [theme.breakpoints.up("md")]: {
            minHeight:cardHeight,
            maxHeight:cardHeight,
        },
        [theme.breakpoints.down("md")]: {
            flexDirection:'column-reverse',
            minWidth:0,
            minWidth:0,
     
        }
    },
    cardDetail:{
        display:'flex',
        minHeight:'100%',
        minWidth:cardDetailWidth,
        maxWidth:cardDetailWidth,
        flexDirection:'column',
        justifyContent:'space-between',
        padding: cardPadding,
        [theme.breakpoints.down("md")]: {
            flexDirection:'column',
            minHeight:0,
            minWidth:"100%",
            maxWidth:"100%",
            padding:'1rem'
        }
    },
    cardTitle:{
        // Not applied when text from cms is uppercase, idk why
        textTransform:'capitalize',
        [theme.breakpoints.down("md")]: {
            fontSize:'28px',
            minWidth:"100%"
        }
    },
    cardLink:{
        textDecoration:'none',
        color: theme.palette.primary.main
    },
    cover: {
        minWidth:imgWidth,
        maxWidth: imgWidth,
        [theme.breakpoints.down("md")]: {
            maxHeight:"200px",
            minHeight:"200px",
        }
    },
}))
export default useStyles