import { makeStyles } from '@material-ui/core/styles'

const height = "2.188rem" // 35px
// const margin = "0.938rem" // 15px
const margin = "0.75rem" // 15px
const useStyles = makeStyles( theme => ({
    buttonLink:{
        textTransform:'capitalize !important',
        maxHeight:height,
        marginRight:margin,
        marginBottom:margin,
        [theme.breakpoints.down("md")]: {
            // maxHeight:'2rem',
            maxHeight:"5rem",

        }
    },
    categoryTagName:{
        [theme.breakpoints.down("md")]: {
            // fontSize:'.95rem'
        }
    }
}))
export default useStyles